<template>
  <div class="container">
    <iframe class="iframe1" src="https://720yun.com/t/53vkt7pmrql?scene_id=73280476" frameborder="0"></iframe>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
    }
  },
  mounted(){
  },
  methods:{
    
  }
}
</script>

<style lang="less" scoped>
.iframe1 {
  width: 100vw;
  height: 100vh;
}
</style>
