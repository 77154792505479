<template>
  <div class="food-box">
      <div class="public-box-img"
      :style="{backgroundImage: 'url(' + coverImgUrl + ')', backgroundSize:'100% 100%', backgroundRepeat: 'no-repeat'}"
      ></div>
      <Djmjone></Djmjone>
  </div>
</template>

<script>
import Djmjone from "../djmjone/djmjone";
export default {
  components: {
    Djmjone
  },
  data() {
    return {
      // coverImgUrl: 'http://qiniu.image.citgroup.cn/zhangjiakou/icon/meishitechan.png'
      coverImgUrl: 'https://qiniu.image.citgroup.cn/sichuan/qiqushan/cms/qqscolumn.jpg'
    }
  },
  mounted() {
    console.log(this.$i18n.locale);
    if(this.$i18n.locale == 'en') {
      this.coverImgUrl = 'https://qiniu.image.citgroup.cn/sichuan/qiqushan/cms/meishi.png';
    }
  }
};
</script>

<style>
.food-box{
    overflow: hidden;
}
.food-box .public-box-img{
    width: 100%;
    height: 600px;
    /* background: url(http://qiniu.image.citgroup.cn/zhangjiakou/icon/meishitechan.png) no-repeat; */
    
    margin: 0px auto ;
}
</style>