<template>
  <div class="djmj-banxin-one">
    <div class="titlefoodProducts">{{ $t('content.foodProducts') }}</div>
    <div class="content" >
      <div class="img" v-for="(item, index) in djmjBanxinOne" :key="index" @click="foodpublicdetails(item.id)">
        <img v-lazy="baseUrl + item.imgUrl" alt="" class="img1" />
        <div class="t1">
          <div class="namefoodProducts">{{ item.title }}</div>
          <div class="detailfoodProducts">
            {{ item.briefInfo }}
          </div>
          <div class="morefoodProducts">{{ $t('content.viewDetails') }}>></div>
        </div>
      </div>
    </div>
    <div class="blocklist">
      <el-pagination
        :page-size="size"
        layout="prev, pager, next"
        :total="totalSize"
        @current-change="currentChange()"
        :current-page.sync="currentPage">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      msg:'美食特产',
      id:0,
      djmjBanxinOne: [],
      baseUrl: "http://qiniu.image.citgroup.cn/sichuan/qiqushan/cms",
      currentPage: 1,
      totalSize:0,
      size: 9,
      apiList: '/api/travelStoreApi',
      detailApi: '/api/travelStoreApi/getDetail/',
      classify: 39,
      language: this.$i18n.locale
    };
  },
  mounted() {
    let api = this.$route.query.api;
    if(api === '/api/featureApi') {
      this.apiList = '/api/featureApi';
      this.detailApi = '/api/featureApi/getDetail/';
      this.classify = 46;
    }
    this.home();
  },
  methods: {
    foodpublicdetails(id) {
      this.$router.push({
        path: "/publicdetails",
        query: {
          detailApi:this.detailApi + id
        },
      });
    },
    async home() {
      let result = await this.$http({
        //url: "/api/travelStoreApi?cxfl=8&classify=39&size=9",
        url: `${this.apiList}?cxfl=8&classify=${this.classify}&size=${this.size}&page=${this.currentPage}&language=${this.language}`,
        method: "get",
      });
      console.log(result);
      this.djmjBanxinOne = result.data.data;
      this.totalSize = result.data.totalSize;
    },
    currentChange() {
      this.home();
    }
  },
};
</script>

<style>
.djmj-banxin-one {
  width: 1200px;
  /* height: 5200px; */
  margin: 32px auto 200px;
  /* background: red; */
}
.djmj-banxin-one .titlefoodProducts {
  /* width: 210px; */
  height: 48px;
  font-size: 51px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #343434;
  line-height: 51px;
  margin: 0px auto 69px;
  text-align: center;
}
.djmj-banxin-one .content {
  width: 1200px;
  /* height: 401px; */
  /* background: grey; */
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: -124px;
}
.djmj-banxin-one .content .img {
  width: 380px;
  height: 280px;
  background: #8ac998;
  margin: 80px auto;
  position: relative;
}
.img1 {
  width: 380px;
  height: 280px;
  background: #8ac998;
  position: absolute;
  left: 0px;
  top: 0px;
}
.djmj-banxin-one .content .img .t1 {
  width: 305px;
  height: 194px;
  background: #ffffff;
  box-shadow: 1px 3px 18px 0px rgba(8, 1, 3, 0.14);
  margin: 207px auto 102px;
  position: absolute;
  left: 38px;
  top: 0px;
}
.djmj-banxin-one .content .img .t1 .namefoodProducts {
  font-size: 18px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #ce5a4e;
  line-height: 45px;
  margin: 0px auto 10px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.djmj-banxin-one .content .img .t1 .detailfoodProducts {
  width: 271px;
  /* height: 68px; */
  /* background: red; */
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  text-align: left;
  margin: 0px auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.djmj-banxin-one .content .img .t1 .morefoodProducts {
  /* width: 90px; */
  height: 21px;
  background: #ce5a4e;
  line-height: 21px;
  text-align: center;
  margin-left: 206px;
  margin-top: 30px;
  font-size: 14px;
  font-family: inpinheiti;
  font-weight: 400;
  color: #ffffff;
  line-height: 24px;
  cursor: pointer;
}

.blocklist{
  margin-top: 100px;
  text-align: center;
}
.el-pagination {
    width: 1200px;
    height: 40px;
}
.el-pagination .btn-prev {
  height: 40px;
}
.el-pagination .btn-prev i{
  font-size: 20px;
}
.el-pagination .btn-prev:hover {
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: #ce594d;
  opacity: 0.6;
  border-radius: 50%;
  text-align: center;
}
.el-pagination .btn-next {
  height: 40px;
}
.el-pagination .btn-next i{
  font-size: 20px;
}
.el-pagination .btn-next:hover {
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: #ce594d;
  opacity: 0.6;
  border-radius: 50%;
  text-align: center;
}
.el-pager {
    height: 100%;
    margin: 0 auto;
    position: relative;
}

.el-pager .number{
    width: 40px;
    height: 40px;
    line-height: 40px;
    background: #f8f8f8;
    opacity: 0.6;
    border-radius: 50%;
    text-align: center;
    color: #000000 !important;
    font-size: 20px;
    font-weight: 300;
    margin-left: 23px;
}
.el-pager .number:hover{
    width: 40px;
    height: 40px;
    line-height: 40px;
    background: #ce594d;
    opacity: 0.6;
    border-radius: 50%;
    text-align: center;
}
.el-pager .active{
    width: 40px;
    height: 40px;
    line-height: 40px;
    background: #ce594d;
    opacity: 0.6;
    border-radius: 50%;
    text-align: center;
}
</style>